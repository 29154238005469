import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import * as firebase from 'firebase';

import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import linkedin_contact from '../../images/linkedin_contact.svg';
import fb_contact from '../../images/fb_contact.svg';
import insta_contact from '../../images/insta_contact.svg';

import letterBlack from '../../images/contact/letterBlack.svg';
import letterBlue from '../../images/contact/letterBlue.svg';

import { FormControl } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import './index.scss';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(90deg, #0998ce 30%, #6fbbd5 86%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgb(150 148 148 / 30%);',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#00054C',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#00054C',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#00054C',
      },
      '&:hover fieldset': {
        borderColor: '#00054C',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00054C',
      },
    },
  },
})(TextField);

const CssTextFieldArea = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#00054C',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#00054C',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#00054C',
      },
      '&:hover fieldset': {
        borderColor: '#00054C',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00054C',
      },
    },
  },
})(TextField);

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = useStyles();

  const [contact, setContact] = useState({
    email: '',
    text: '',
    subject: '',
  });

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);

  const [send, setSend] = useState(false);

  const handleChange = (prop) => (event) => {
    setContact({ ...contact, [prop]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setSend(false);

    const callableReturnMessage = firebase
      .functions()
      .httpsCallable('sendContactEmail');

    callableReturnMessage({
      email: contact.email,
      text: contact.text,
      subject: contact.subject,
    })
      .then((result) => {
        setSend(true);
        setError(false);
        setLoading(false);
        setContact({ email: '', text: '', subject: '' });
      })
      .catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
        setLoading(false);
        setError(true);
      });
  };

  return (
    <div className="contact">
      <section className="form">
        <div className="contact-container">
          <img
            draggable="false"
            className="letter-black"
            src={letterBlack}
            alt="letter black"
          />
          <img
            draggable="false"
            className="letter-blue"
            src={letterBlue}
            alt="letter blue"
          />
          <h2>
            <FormattedMessage id="1" defaultMessage="CGV" />
          </h2>
          <div className="line-color">
            <div className="color-1" />
            <div className="color-2" />
            <div className="color-1" />
            <div className="color-2" />
            <div className="color-1" />
            <div className="color-2" />
          </div>
          <p>
            <FormattedMessage id="1.1" defaultMessage="CGV" />{' '}
          </p>
          <p>
            <FormattedMessage id="1.2" defaultMessage="CGV" />{' '}
            <a href="mailto:contacts@postocards.com?subject=Contacts">
              <span className="span-color">
                contacts@postocards.com
              </span>
            </a>
            .
          </p>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-content">
              <FormControl className="label-input">
                <CssTextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  InputProps={{
                    style: {
                      fontSize: 18,
                      minWidth: 200,
                      backgroundColor: 'white',
                    },
                  }}
                  className="input"
                  label={
                    <FormattedMessage id="2.1" defaultMessage="CGV" />
                  }
                  type="email"
                  value={contact.email}
                  onChange={handleChange('email')}
                />
              </FormControl>
              <FormControl className="label-input">
                <CssTextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  InputProps={{
                    style: {
                      fontSize: 18,
                      minWidth: 200,
                      backgroundColor: 'white',
                    },
                  }}
                  className="input"
                  label={
                    <FormattedMessage id="2.2" defaultMessage="CGV" />
                  }
                  value={contact.subject}
                  onChange={handleChange('subject')}
                />
              </FormControl>
            </div>
            <div className="text-area">
              <FormControl className="label-textarea">
                <CssTextFieldArea
                  id="outlined-basic"
                  required
                  rows={6}
                  multiline={true}
                  rowsMax={6}
                  variant="outlined"
                  InputProps={{
                    style: {
                      fontSize: 18,
                      minWidth: 200,
                      backgroundColor: 'white',
                    },
                  }}
                  className="input"
                  label={
                    <FormattedMessage id="2.3" defaultMessage="CGV" />
                  }
                  type="text"
                  value={contact.text}
                  onChange={handleChange('text')}
                />
              </FormControl>
            </div>
            {error && <p>Erreur veuillez réessayer plus tard</p>}
            {loading && <p>Message en cours</p>}
            {send && <p>Message envoyé</p>}
            <Button
              type="submit"
              disabled={loading}
              className={classes.root}
            >
              <FormattedMessage id="2.4" defaultMessage="CGV" />
            </Button>
          </form>
        </div>
      </section>

      <div className="sub-title">
        <h2>
          <FormattedMessage id="3" defaultMessage="CGV" />
        </h2>
      </div>
      <div className="social">
        <a
          href={'https://www.instagram.com/postocards/'}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            draggable="false"
            className="social-link"
            src={insta_contact}
            alt="instagram"
          />
        </a>

        <a
          href={'https://www.facebook.com/'}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            draggable="false"
            className="social-link"
            src={fb_contact}
            alt="facebook"
          />
        </a>

        <a
          href={'https://www.linkedin.com/postocards/'}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            draggable="false"
            className="social-link"
            src={linkedin_contact}
            alt="linkedin"
          />
        </a>
      </div>
    </div>
  );
}

export default withRouter(Contact);
