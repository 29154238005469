import React from 'react';
import facebook from '../../images/fb.svg';
import instagram from '../../images/instagram.svg';
import HUAWEI from '../../images/HUAWEI.svg';
import APPLE from '../../images/apple.svg';
import GOOGLE from '../../images/google.svg';
import linkedin from '../../images/linkedin.svg';
import iconPostocards from '../../images/iconPostocards.png';
import { Link } from 'react-router-dom';
import './index.scss';
import { FormattedMessage } from 'react-intl';

export function Footer() {
  const link = "https://app-postocards.firebaseapp.com" ;//'https://tim-app-postocards-test.firebaseapp.com';
  return (
    <section className="footer">
      <div className="nav-footer center-page">
        <div className="center-content">
          <div className="logo-container">
            <div className="logo-content">
              <img
                draggable="false"
                className="iconPostocards"
                src={iconPostocards}
                alt="icon-Postocards"
              />
            </div>

            <h3>
              <FormattedMessage id="1" defaultMessage="CGV" />
            </h3>
            <div className="social-icon">
              <div>
                <a
                  href="https://apps.apple.com/fr/app/postocards/id1536725764"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    draggable="false"
                    className="appleLink"
                    src={APPLE}
                    alt="APPLE"
                  />
                </a>
              </div>
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.postocards.mobile"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    draggable="false"
                    className="googleLink"
                    src={GOOGLE}
                    alt="GOOGLE"
                  />
                </a>
              </div>
              <div>
                <a
                  href="https://appgallery.huawei.com/#/app/C103838737"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    draggable="false"
                    className="huawei"
                    src={HUAWEI}
                    alt="HUAWEI"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="col-1">
            <h3>
              <FormattedMessage id="2" defaultMessage="CGV" />
            </h3>
            <Link to="/cgv">
              <p className="social-link">
                <FormattedMessage id="2.2" defaultMessage="CGV" />
              </p>
            </Link>
            <Link to="/mention-legal">
              <p className="social-link">
                <FormattedMessage id="2.3" defaultMessage="CGV" />
              </p>
            </Link>
          </div>

          <div className="col-1">
            <h3>
              <FormattedMessage id="3" defaultMessage="CGV" />
            </h3>
            <Link to="/">
              <p className="social-link">
                <FormattedMessage id="3.1" defaultMessage="CGV" />
              </p>
            </Link>
            <a href={link} target="_blank">
              <p className="social-link">
                <FormattedMessage id="3.5" defaultMessage="CGV" />
              </p>
            </a>
            <Link to="/publipostage">
              <p className="social-link">
                <FormattedMessage id="3.2" defaultMessage="CGV" />
              </p>
            </Link>
            <Link to="/qui-sommes-nous">
              <p className="social-link">
                <FormattedMessage id="3.3" defaultMessage="CGV" />
              </p>
            </Link>
            <Link to="/des-questions">
              <p className="social-link">
                <FormattedMessage id="3.4" defaultMessage="CGV" />
              </p>
            </Link>
          </div>

          <div className="social">
            <a
              href={'https://www.instagram.com/postocards/'}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                draggable="false"
                className="social-link zoom-social"
                src={instagram}
                alt="instagram"
              />
            </a>

            <a
              href={'https://www.facebook.com/'}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                draggable="false"
                className="social-link zoom-social"
                src={facebook}
                alt="facebook"
              />
            </a>

            <a
              href={'https://www.linkedin.com/postocards/'}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                draggable="false"
                className="social-link zoom-social"
                src={linkedin}
                alt="linkedin"
              />
            </a>
          </div>
        </div>
      </div>
      <p className="legal-mention">
        Copyright ©2022 postocards.com , Tous droits réservés
      </p>
    </section>
  );
}

export default Footer;
