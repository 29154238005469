import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import './index.scss';

export function Cgv() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="cgv">
      <h2 className="title">
        <FormattedMessage id="titre" defaultMessage="CGV" />
      </h2>
      <div className="line-color">
        <div className="color-1" />
        <div className="color-2" />
        <div className="color-1" />
        <div className="color-2" />
        <div className="color-1" />
        <div className="color-2" />
      </div>
      <div className="content-cgv">
        <h2>
          1. <FormattedMessage id="1" defaultMessage="CGV" />
        </h2>
        <p>
          1.1 <FormattedMessage id="1.1" defaultMessage="CGV" />
        </p>
        <p>
          1.2 <FormattedMessage id="1.2" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          2 <FormattedMessage id="2" defaultMessage="CGV" />
        </h2>
        <p>
          2.1 <FormattedMessage id="2.1" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          3. <FormattedMessage id="3" defaultMessage="CGV" />
        </h2>
        <p>
          3.1 <FormattedMessage id="3.1" defaultMessage="CGV" />
        </p>
        <p>
          3.1.1 <FormattedMessage id="3.1.1" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          4. <FormattedMessage id="4" defaultMessage="CGV" />
        </h2>
        <p>
          4.1 <FormattedMessage id="4.1" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          5. <FormattedMessage id="5" defaultMessage="CGV" />
        </h2>
        <p>
          5.1 <FormattedMessage id="5.1" defaultMessage="CGV" />
        </p>
        <p>
          5.2 <FormattedMessage id="5.2" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          6. <FormattedMessage id="6" defaultMessage="CGV" />
        </h2>
        <p>
          6.1 <FormattedMessage id="6.1" defaultMessage="CGV" />
        </p>
        <p>
          6.2 <FormattedMessage id="6.2" defaultMessage="CGV" />
        </p>
        <p>
          6.3 <FormattedMessage id="6.3" defaultMessage="CGV" />
        </p>
        <p>
          6.4 <FormattedMessage id="6.4" defaultMessage="CGV" />
        </p>
        <p>
          6.5 <FormattedMessage id="6.5" defaultMessage="CGV" />
        </p>
        <p>
          6.6 <FormattedMessage id="6.6" defaultMessage="CGV" />
        </p>
        <p>
          6.7 <FormattedMessage id="6.7" defaultMessage="CGV" />
        </p>
        <p>
          6.8 <FormattedMessage id="6.8" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          7. <FormattedMessage id="7" defaultMessage="CGV" />
        </h2>
        <p>
          7.1 <FormattedMessage id="7.1" defaultMessage="CGV" />
        </p>
        <p>
          7.2 <FormattedMessage id="7.2" defaultMessage="CGV" />
        </p>
        <p>
          7.3 <FormattedMessage id="7.3" defaultMessage="CGV" />
        </p>
        <p>
          7.4 <FormattedMessage id="7.4" defaultMessage="CGV" />
        </p>
        <p>
          7.5 <FormattedMessage id="7.5" defaultMessage="CGV" />
        </p>
        <p>
          7.6 <FormattedMessage id="7.6" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          8. <FormattedMessage id="8" defaultMessage="CGV" />
        </h2>
        <p>
          8.1 <FormattedMessage id="8.1" defaultMessage="CGV" />
        </p>
        <p>
          8.2 <FormattedMessage id="8.2" defaultMessage="CGV" />
        </p>
        <p>
          8.3 <FormattedMessage id="8.3" defaultMessage="CGV" />
        </p>
        <p>
          8.4 <FormattedMessage id="8.4" defaultMessage="CGV" />
        </p>
        <p>
          8.5 <FormattedMessage id="8.5" defaultMessage="CGV" />
        </p>
        <p>
          8.6 <FormattedMessage id="8.6" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          9. <FormattedMessage id="9" defaultMessage="CGV" />
        </h2>
        <p>
          9.1 <FormattedMessage id="9.1" defaultMessage="CGV" />
        </p>
        <p>
          9.2 <FormattedMessage id="9.2" defaultMessage="CGV" />
        </p>
        <p>
          9.3 <FormattedMessage id="9.3" defaultMessage="CGV" />
        </p>
        <p>
          9.4 <FormattedMessage id="9.4" defaultMessage="CGV" />
        </p>
        <p>
          9.5 <FormattedMessage id="9.5" defaultMessage="CGV" />
        </p>
        <p>
          9.6 <FormattedMessage id="9.6" defaultMessage="CGV" />
        </p>
        <p>
          9.7 <FormattedMessage id="9.7" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          10. <FormattedMessage id="10" defaultMessage="CGV" />
        </h2>
        <p>
          10.1 <FormattedMessage id="10.1" defaultMessage="CGV" />
        </p>
        <p>
          10.2 <FormattedMessage id="10.2" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          11. <FormattedMessage id="11" defaultMessage="CGV" />
        </h2>
        <p>
          11.1 <FormattedMessage id="11.1" defaultMessage="CGV" />
        </p>
        <p>
          11.2 <FormattedMessage id="11.2" defaultMessage="CGV" />
        </p>
        <p>
          11.3 <FormattedMessage id="11.3" defaultMessage="CGV" />
        </p>
        <p>
          11.4 <FormattedMessage id="11.4" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          12. <FormattedMessage id="12" defaultMessage="CGV" />
        </h2>
        <p>
          12.1 <FormattedMessage id="12.1" defaultMessage="CGV" />
        </p>
        <p>
          12.1.1 <FormattedMessage id="12.1.1" defaultMessage="CGV" />
        </p>
        <p>
          12.2 <FormattedMessage id="12.2" defaultMessage="CGV" />
        </p>
        <p>
          12.3 <FormattedMessage id="12.3" defaultMessage="CGV" />
        </p>
        <p>
          12.4 <FormattedMessage id="12.4" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          13. <FormattedMessage id="13" defaultMessage="CGV" />
        </h2>
        <p>
          13.1 <FormattedMessage id="13.1" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          14. <FormattedMessage id="14" defaultMessage="CGV" />
        </h2>
        <p>
          14.1 <FormattedMessage id="14.1" defaultMessage="CGV" />
        </p>
        <p>
          14.1.1 <FormattedMessage id="14.1.1" defaultMessage="CGV" />
        </p>
        <p>
          14.2 <FormattedMessage id="14.2" defaultMessage="CGV" />
        </p>
        <p>
          14.3 <FormattedMessage id="14.3" defaultMessage="CGV" />
        </p>
        <p>
          14.4 <FormattedMessage id="14.4" defaultMessage="CGV" />
        </p>
        <p>
          14.5 <FormattedMessage id="14.5" defaultMessage="CGV" />
        </p>
        <p>
          14.6 <FormattedMessage id="14.6" defaultMessage="CGV" />
        </p>
        <p>
          14.6.1 <FormattedMessage id="14.6.1" defaultMessage="CGV" />
        </p>
        <p>
          14.6.2 <FormattedMessage id="14.6.2" defaultMessage="CGV" />
        </p>
        <p>
          14.6.3 <FormattedMessage id="14.6.3" defaultMessage="CGV" />
        </p>
        <p>
          14.6.4 <FormattedMessage id="14.6.4" defaultMessage="CGV" />
        </p>
        <p>
          14.6.5 <FormattedMessage id="14.6.5" defaultMessage="CGV" />
        </p>
        <p>
          14.6.6 <FormattedMessage id="14.6.6" defaultMessage="CGV" />
        </p>
        <p>
          14.7 <FormattedMessage id="14.7" defaultMessage="CGV" />
        </p>
        <p>
          14.8 <FormattedMessage id="14.8" defaultMessage="CGV" />
        </p>
        <p>
          14.9 <FormattedMessage id="14.9" defaultMessage="CGV" />
        </p>
        <p>
          14.10 <FormattedMessage id="14.10" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          15. <FormattedMessage id="15" defaultMessage="CGV" />
        </h2>
        <p>
          15.1 <FormattedMessage id="15.1" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          15. <FormattedMessage id="15" defaultMessage="CGV" />
        </h2>
        <p>
          15.1 <FormattedMessage id="15.1" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          16. <FormattedMessage id="16" defaultMessage="CGV" />
        </h2>
        <p>
          16.1 <FormattedMessage id="16.1" defaultMessage="CGV" />
        </p>
        <p>
          16.1.1 <FormattedMessage id="16.1.1" defaultMessage="CGV" />
        </p>
        <p>
          16.1.2 <FormattedMessage id="16.1.2" defaultMessage="CGV" />
        </p>
        <p>
          16.1.3 <FormattedMessage id="16.1.3" defaultMessage="CGV" />
        </p>
        <p>
          16.1.1 <FormattedMessage id="16.1.1" defaultMessage="CGV" />
        </p>
        <p>
          16.1.5 <FormattedMessage id="16.1.5" defaultMessage="CGV" />
        </p>
        <p>
          16.1.1 <FormattedMessage id="16.1.6" defaultMessage="CGV" />
        </p>
        <p>
          16.1.7 <FormattedMessage id="16.1.7" defaultMessage="CGV" />
        </p>
        <p>
          16.2 <FormattedMessage id="16.2" defaultMessage="CGV" />
        </p>
        <p>
          16.3 <FormattedMessage id="16.3" defaultMessage="CGV" />
        </p>
        <p>
          16.4 <FormattedMessage id="16.4" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          17. <FormattedMessage id="17" defaultMessage="CGV" />
        </h2>
        <p>
          17.1 <FormattedMessage id="17.1" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          18. <FormattedMessage id="18" defaultMessage="CGV" />
        </h2>
        <p>
          18.1 <FormattedMessage id="18.1" defaultMessage="CGV" />
        </p>
        <p>
          18.2 <FormattedMessage id="18.2" defaultMessage="CGV" />
        </p>
        <p>
          18.3 <FormattedMessage id="18.3" defaultMessage="CGV" />
        </p>
        <p>
          18.4 <FormattedMessage id="18.4" defaultMessage="CGV" />
        </p>
        <p>
          18.4.1 <FormattedMessage id="18.4.1" defaultMessage="CGV" />
        </p>
        <p>
          18.5 <FormattedMessage id="18.5" defaultMessage="CGV" />
        </p>
        <p>
          18.6 <FormattedMessage id="18.6" defaultMessage="CGV" />
        </p>
        <p>
          18.7 <FormattedMessage id="18.7" defaultMessage="CGV" />
        </p>
        <p>
          18.8 <FormattedMessage id="18.8" defaultMessage="CGV" />
        </p>
        <p>
          18.9 <FormattedMessage id="18.9" defaultMessage="CGV" />
        </p>
        <p>
          18.10 <FormattedMessage id="18.10" defaultMessage="CGV" />
        </p>
        <p>
          18.11 <FormattedMessage id="18.11" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          19. <FormattedMessage id="19" defaultMessage="CGV" />
        </h2>
        <p>
          19.1 <FormattedMessage id="19.1" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          20. <FormattedMessage id="20" defaultMessage="CGV" />
        </h2>
        <p>
          20.1 <FormattedMessage id="20.1" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          21. <FormattedMessage id="21" defaultMessage="CGV" />
        </h2>
        <p>
          21.1 <FormattedMessage id="21.1" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          22. <FormattedMessage id="22" defaultMessage="CGV" />
        </h2>
        <p>
          22.1 <FormattedMessage id="22.1" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          23. <FormattedMessage id="23" defaultMessage="CGV" />
        </h2>
        <p>
          23.1 <FormattedMessage id="23.1" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          24. <FormattedMessage id="24" defaultMessage="CGV" />
        </h2>
        <p>
          24.1 <FormattedMessage id="24.1" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          25. <FormattedMessage id="25" defaultMessage="CGV" />
        </h2>
        <p>
          25.1 <FormattedMessage id="25.1" defaultMessage="CGV" />
        </p>
        <p>
          25.1.1 <FormattedMessage id="25.1.1" defaultMessage="CGV" />
        </p>
        <p>
          25.1.2 <FormattedMessage id="25.1.1" defaultMessage="CGV" />
        </p>
      </div>
      <div className="content-cgv">
        <h2>
          <FormattedMessage id="Annexe" defaultMessage="CGV" />
        </h2>
        <p>
          <FormattedMessage id="Annexe.1" defaultMessage="CGV" />
        </p>
        <p>
          <FormattedMessage id="Annexe.2" defaultMessage="CGV" />
        </p>
        <p>
          <FormattedMessage id="Annexe.sign" defaultMessage="CGV" />
        </p>
        <p>
          <FormattedMessage id="Annexe.name" defaultMessage="CGV" />
        </p>
        <p>
          <FormattedMessage id="Annexe.adress" defaultMessage="CGV" />
        </p>
        <p>
          <FormattedMessage
            id="Annexe.signature"
            defaultMessage="CGV"
          />
        </p>
        <p>
          <FormattedMessage id="Annexe.num" defaultMessage="CGV" />
        </p>
        <p>
          <FormattedMessage id="Annexe.date" defaultMessage="CGV" />
        </p>
      </div>
    </div>
  );
}

export default Cgv;
