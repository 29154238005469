import React from 'react';
import { LegalContent } from '../../components';

import './index.scss';

export function Legal() {
  return (
    <div className="legal">
      <LegalContent />
    </div>
  );
}

export default Legal;
