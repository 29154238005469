import React, { useEffect } from 'react';
import './index.scss';

import { Player } from 'video-react';
import Grid from '@material-ui/core/Grid';
import madeSmiley from '../../images/home/madeSmiley.png';
import { FormattedMessage } from 'react-intl';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="about">
      <div className="content-about">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className="row-1">
              <h2> <FormattedMessage id="11" defaultMessage="CGV" /></h2>
              <div className="line-color">
                <div className="color-1" />
                <div className="color-2" />
                <div className="color-1" />
                <div className="color-2" />
                <div className="color-1" />
                <div className="color-2" />
              </div>
              <div className="content-text">
                <p>
                <FormattedMessage id="11.1" defaultMessage="CGV" />
                </p>
                <p>
                <FormattedMessage id="11.2" defaultMessage="CGV" />
                </p>
                <p>
                <FormattedMessage id="11.3" defaultMessage="CGV" />
                </p>
                <p>
                <FormattedMessage id="11.4" defaultMessage="CGV" />
                </p>
                <p>
                <FormattedMessage id="11.5" defaultMessage="CGV" />
                </p>
                <p>
                <FormattedMessage id="11.6" defaultMessage="CGV" />
                </p>
                <p>
                <FormattedMessage id="11.7" defaultMessage="CGV" />
                </p>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className="video">
              <Player>
                <source
                  src={
                    'https://firebasestorage.googleapis.com/v0/b/card4u-9d3a1.appspot.com/o/template_images%2Foutput(video-cutter-js.com).mp4?alt=media&token=933a8af6-62f4-43bf-9f19-c524cc4652f5'
                  }
                />
              </Player>
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className="content-image">
              <img
                draggable="false"
                className="image-made"
                src={madeSmiley}
                alt="postcards and smiley"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <h2> <FormattedMessage id="12" defaultMessage="CGV" /></h2>
            <div className="line-color">
              <div className="color-1" />
              <div className="color-2" />
              <div className="color-1" />
              <div className="color-2" />
              <div className="color-1" />
              <div className="color-2" />
            </div>
            <p>
            <FormattedMessage id="12.1" defaultMessage="CGV" />
            </p>
            <p>
            <FormattedMessage id="12.2" defaultMessage="CGV" />
            </p>
            <p>
            <FormattedMessage id="12.3" defaultMessage="CGV" />
            </p>
            <p>
            <FormattedMessage id="12.4" defaultMessage="CGV" />
            </p>
            <p>
            <FormattedMessage id="12.6" defaultMessage="CGV" />
            </p>
            <p>
            <FormattedMessage id="12.8" defaultMessage="CGV" />
            </p>
            <p>
            <FormattedMessage id="12.9" defaultMessage="CGV" />
            </p>
            <p>
            <FormattedMessage id="12.10" defaultMessage="CGV" />
            </p>
            <p>
            <FormattedMessage id="12.12" defaultMessage="CGV" />
            </p>
            <p>
            <FormattedMessage id="12.13" defaultMessage="CGV" />
            </p>
            <p>
            <FormattedMessage id="12.14" defaultMessage="CGV" />
            </p>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default About;
