import React from 'react';

import HUAWEI from '../../images/HUAWEI.svg';
import APPLE from '../../images/apple.svg';
import GOOGLE from '../../images/google.svg';
import tel from '../../images/home/tel.png';

import heart from '../../images/home/heart.svg';
import cloud from '../../images/home/cloud.svg';
import trait from '../../images/home/trait.svg';
import smiley from '../../images/home/smiley.svg';

import './index.scss';
import { FormattedMessage } from 'react-intl';
import Slider from './Slider';

const Bordereau = () => {
  return (
    <>
    <section className="bordereau">
      <Slider />
      <div className="home-left">
        {/* <div className="title-text">
          <h2>
            <FormattedMessage id="1" defaultMessage="CGV" />
          </h2>
          <p className="sub-title">
            <FormattedMessage id="1.1" defaultMessage="CGV" />
          </p>
        </div> */}

        <div className="content-download">
          <p className="download">
            <FormattedMessage id="1.2" defaultMessage="CGV" />
          </p>
        </div>
        <div className="line-color">
          <div className="color-1" />
          <div className="color-2" />
          <div className="color-1" />
          <div className="color-2" />
          <div className="color-1" />
          <div className="color-2" />
        </div>
        <div className="title-icons">
          <a
            href="https://apps.apple.com/fr/app/postocards/id1536725764"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              draggable="false"
              className="link-store"
              src={APPLE}
              alt="appleLink"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.postocards.mobile"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              draggable="false"
              className="link-store"
              src={GOOGLE}
              alt="googleLink"
            />
          </a>
          <a
            href="https://appgallery.huawei.com/#/app/C103838737"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              draggable="false"
              className="link-store"
              src={HUAWEI}
              alt="huawei"
            />
          </a>
        </div>
      </div>
      {/* <div className="content-right">
        <div className="content-image">
          <img
            draggable="false"
            className="heart"
            src={heart}
            alt="heart"
          />
          <img
            draggable="false"
            className="cloud"
            src={cloud}
            alt="cloud"
          />
          <img
            draggable="false"
            className="trait"
            src={trait}
            alt="decoration"
          />
          <img
            draggable="false"
            className="smiley"
            src={smiley}
            alt="smiley"
          />
          <img
            draggable="false"
            className="bordereau-tel"
            src={tel}
            alt="tel"
          />
        </div>
      </div> */}
    </section>
    <section className="bordereau">
    <div className="home-left home-text">
      <div className="title-text">
        <h2>
          <FormattedMessage id="1" defaultMessage="CGV" />
        </h2>
        <p className="sub-title">
          <FormattedMessage id="1.1" defaultMessage="CGV" />
        </p>
      </div>

      {/* <div className="content-download">
        <p className="download">
          <FormattedMessage id="1.2" defaultMessage="CGV" />
        </p>
      </div>
      <div className="line-color">
        <div className="color-1" />
        <div className="color-2" />
        <div className="color-1" />
        <div className="color-2" />
        <div className="color-1" />
        <div className="color-2" />
      </div> */}
      {/* <div className="title-icons">
        <a
          href="https://apps.apple.com/fr/app/postocards/id1536725764"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            draggable="false"
            className="link-store"
            src={APPLE}
            alt="appleLink"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.postocards.mobile"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            draggable="false"
            className="link-store"
            src={GOOGLE}
            alt="googleLink"
          />
        </a>
        <a
          href="https://appgallery.huawei.com/#/app/C103838737"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            draggable="false"
            className="link-store"
            src={HUAWEI}
            alt="huawei"
          />
        </a>
      </div> */}
    </div>
    <div className="content-right">
      <div className="content-image">
        <img
          draggable="false"
          className="heart"
          src={heart}
          alt="heart"
        />
        <img
          draggable="false"
          className="cloud"
          src={cloud}
          alt="cloud"
        />
        <img
          draggable="false"
          className="trait"
          src={trait}
          alt="decoration"
        />
        <img
          draggable="false"
          className="smiley"
          src={smiley}
          alt="smiley"
        />
        <img
          draggable="false"
          className="bordereau-tel"
          src={tel}
          alt="tel"
        />
      </div>
    </div>
  </section>
  </>
  );
};

export default Bordereau;
