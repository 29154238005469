// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import Bordereau from '../../components/Bordereau';
import './index.scss';

import Button from '@material-ui/core/Button';
import { Player } from 'video-react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import avion from '../../images/home/avion.svg';
import promo from '../../images/home/promo.svg';
import pouce from '../../images/home/pouce.svg';

import card from '../../images/home/card.svg';
import gallery from '../../images/home/gallery.svg';
import write from '../../images/home/write.svg';
import three from '../../images/home/three.svg';

import capture from '../../images/home/capture.svg';
import sun from '../../images/home/sun.svg';

import how1 from '../../images/home/how1.svg';
import how2 from '../../images/home/how2.svg';
import how3 from '../../images/home/how3.svg';

import product1 from '../../images/home/product1.png';
import product2 from '../../images/home/product2.png';
import product3 from '../../images/home/product3.png';
import product4 from '../../images/home/product4.png';
import product5 from '../../images/home/product5.png';
import product6 from '../../images/home/selfies.png';

import bubble from '../../images/home/bubble.svg';
import traitBlue from '../../images/home/traitBlue.svg';
import traitOrange from '../../images/home/traitOrange.svg';

import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(90deg, #0998ce 30%, #6fbbd5 86%)',
    border: 0,
    borderRadius: 15,
    boxShadow: '0 3px 5px 2px rgb(150 148 148 / 30%);',
    color: 'white',
    height: 48,
    padding: '0 30px',
    marginTop: 50,
    fontFamily: 'PoppinsBold',
  },
  product: {
    marginTop: 25,
  },
});

const Home = (props) => {
  const classes = useStyles();

  var userAgent = navigator.userAgent || navigator.vendor;
/*
  const link =
    /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
      ? 'https://play.google.com/store/apps/details?id=com.postocards.mobile'
      : 'https://apps.apple.com/fr/app/postocards/id1536725764';
*/
const link = "https://app-postocards.firebaseapp.com" ;//'https://tim-app-postocards-test.firebaseapp.com';


      /*<div className="info-app">
        <ErrorOutlineIcon />
        <p>
          <FormattedMessage id="info" defaultMessage="info-app" />
        </p>
      </div>
      */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="home">
      <Bordereau />
      <div className="content-bandeau">
        <div className="content">
          <div>
            <img
              draggable="false"
              className="content-logo-home"
              src={pouce}
              alt="pouce"
            />
            <p className="sub-title">
              <FormattedMessage id="2.1" defaultMessage="CGV" />
            </p>
          </div>
          <div className='second-child'>
            <img
              draggable="false"
              className="content-logo-home"
              src={promo}
              alt="promo"
            />
            <p className="sub-title">
              <FormattedMessage id="2.2" defaultMessage="CGV" />
            </p>
          </div>
          <div className="third-child">
            <img
              draggable="false"
              className="content-logo-home"
              src={avion}
              alt="avion"
            />
            <p className="sub-title">
              <FormattedMessage id="2.3" defaultMessage="CGV" />
            </p>
          </div>
        </div>
      </div>
      <div className="content-home">
        <h2>
          <FormattedMessage id="3" defaultMessage="CGV" />
        </h2>
        <div className="line-color">
          <div className="color-1" />
          <div className="color-2" />
          <div className="color-1" />
          <div className="color-2" />
          <div className="color-1" />
          <div className="color-2" />
        </div>

        <div className="content-how">
          <div className="content">
            <div className="step">
              <div className="background-content">
                <img
                  draggable="false"
                  className="content-logo"
                  src={card}
                  alt="card"
                />
              </div>
              <h2 className="step-detail">
                <FormattedMessage id="3.1" defaultMessage="CGV" />
              </h2>
            </div>
            <img
              draggable="false"
              className="content-line1"
              src={how1}
              alt="line-1"
            />
            <div className="step">
              <div className="background-content">
                <img
                  draggable="false"
                  className="content-logo"
                  src={gallery}
                  alt="gallery"
                />
              </div>
              <h2 className="step-detail step-detail-2">
                <FormattedMessage id="3.2" defaultMessage="CGV" />
              </h2>
            </div>
            <img
              draggable="false"
              className="content-line2"
              src={how2}
              alt="line-2"
            />
            <div className="step">
              <div className="background-content">
                <img
                  draggable="false"
                  className="content-logo"
                  src={write}
                  alt="write"
                />
              </div>
              <h2 className="step-detail step-detail-3">
                <FormattedMessage id="3.3" defaultMessage="CGV" />
              </h2>
            </div>
            <img
              draggable="false"
              className="content-line3"
              src={how3}
              alt="line-3"
            />
            <div className="step">
              <div className="background-content">
                <img
                  draggable="false"
                  className="content-logo"
                  src={three}
                  alt="sun-smiley"
                />
              </div>
              <h2 className="step-detail step-detail-4">
                <FormattedMessage id="3.4" defaultMessage="CGV" />
              </h2>
            </div>
          </div>
          <a href={link} rel="noopener noreferrer" target="_blank">
            <Button onClick={() => {}} className={classes.root}>
              <FormattedMessage id="3.5" defaultMessage="CGV" />
            </Button>
          </a>
        </div>

        <section className="home-video">
          <h2>
            <FormattedMessage id="4" defaultMessage="CGV" />
          </h2>
          <div className="line-color">
            <div className="color-1" />
            <div className="color-2" />
            <div className="color-1" />
            <div className="color-2" />
            <div className="color-1" />
            <div className="color-2" />
          </div>
          <div className="video">
            <Player>
              <source
                src={
                  'https://firebasestorage.googleapis.com/v0/b/card4u-9d3a1.appspot.com/o/template_images%2Fpostocards_presentation_04.mp4?alt=media&token=420aa1de-2aec-416a-973e-1bcdb3bbcae6'
                }
              />
            </Player>
            <img
              draggable="false"
              className="video-icon-1"
              src={sun}
              alt="sun"
            />
            <img
              draggable="false"
              className="video-icon-2"
              src={capture}
              alt="capture"
            />
          </div>
        </section>
        <section className="products">
          <h2>
            <FormattedMessage id="5" defaultMessage="CGV" />
          </h2>
          <div className="line-color">
            <div className="color-1" />
            <div className="color-2" />
            <div className="color-1" />
            <div className="color-2" />
            <div className="color-1" />
            <div className="color-2" />
          </div>

          <div className="container">
            <div className="row-1 space-row">
              <div className="content-product">
                <div className="content-title">
                  <h3>
                    <FormattedMessage id="5.1" defaultMessage="CGV" />
                  </h3>
                  <img
                    draggable="false"
                    className="image-title"
                    src={traitOrange}
                    alt="traitOrange"
                  />
                </div>
                <div className="description">
                  <p>
                    <FormattedMessage id="5.2" defaultMessage="CGV" />
                  </p>
                  <p>
                    <FormattedMessage id="5.3" defaultMessage="CGV" />
                  </p>
                  <p>
                    <FormattedMessage id="5.4" defaultMessage="CGV" />
                  </p>
                  <p>
                    <FormattedMessage id="5.5" defaultMessage="CGV" />
                  </p>
                  <a
                    href={link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Button
                      onClick={() => {}}
                      // @ts-ignore
                      className={[classes.root, classes.product]}
                    >
                      <p>
                        <FormattedMessage
                          id="6"
                          defaultMessage="CGV"
                        />
                      </p>
                    </Button>
                  </a>
                </div>
              </div>
              <div className="row1-image">
                <img
                  draggable="false"
                  className="product1"
                  src={product1}
                  alt="product1"
                />
              </div>
            </div>
            <div className="row-2 space-row ">
              <div className="row2-image">
                <img
                  draggable="false"
                  className="product2"
                  src={product2}
                  alt="product2"
                />
              </div>
              <div className="content-product">
                <div className="content-title">
                  <h3>
                    <FormattedMessage id="6.1" defaultMessage="CGV" />
                  </h3>
                </div>
                <div className="description">
                  <p>
                    <FormattedMessage id="6.2" defaultMessage="CGV" />
                  </p>
                  <p>
                    <FormattedMessage id="6.3" defaultMessage="CGV" />
                  </p>
                  <p>
                    <FormattedMessage id="6.4" defaultMessage="CGV" />
                  </p>
                  <a
                    href={link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Button
                      onClick={() => {}}
                      className={classes.root}
                    >
                      <FormattedMessage
                        id="6.5"
                        defaultMessage="CGV"
                      />
                    </Button>
                  </a>
                </div>
              </div>

              <div className="content-color">
                <div className="row-background-line"></div>
                <div className="row-background"></div>
              </div>
            </div>

            <div className="row-3 space-row space-top">
              <div className="content-product">
                <div className="content-title">
                  <h3>
                    <FormattedMessage id="10" defaultMessage="CGV" />
                  </h3>
                  <img
                    draggable="false"
                    className="image-title bubble"
                    src={bubble}
                    alt="bubble"
                  />
                </div>
                <div className="description">
                  <p>
                    <FormattedMessage
                      id="10.1"
                      defaultMessage="CGV"
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="10.2"
                      defaultMessage="CGV"
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="10.3"
                      defaultMessage="CGV"
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="10.4"
                      defaultMessage="CGV"
                    />
                  </p>
                  <a
                    href={link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Button
                      onClick={() => {}}
                      // @ts-ignore
                      className={[classes.root, classes.product]}
                    >
                      <FormattedMessage
                        id="10.5"
                        defaultMessage="CGV"
                      />
                    </Button>
                  </a>
                </div>
              </div>
              <div className="row3-image">
                <img
                  draggable="false"
                  className="product6"
                  src={product6}
                  alt="product6"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Home;
