import React, { useState } from "react";
import { Link } from "react-router-dom";

import Container from "@material-ui/core/Container";
import iconPostocards from "../../images/iconPostocards.jpg";

import france from "../../images/countries/france.svg";
import espagne from "../../images/countries/espagne.svg";
import portugal from "../../images/countries/portugal.svg";
import italie from "../../images/countries/italie.svg";
import netherlands from "../../images/countries/netherlands.svg";
import allemagne from "../../images/countries/allemagne.svg";
import luxembourg from "../../images/countries/luxembourg.svg";
import angleterre from "../../images/countries/united-kingdom.svg";

import "./index.scss";
import { connect } from "react-redux";
import { changeLang } from "../../store/actions/langActions";
import { FormattedMessage } from "react-intl";

const flagChoice = {
  'fr': france,
  'en': angleterre,
  'lu': luxembourg,
  'it': italie,
  'pt': portugal,
  'es': espagne,
  'de': allemagne,
  'nl': luxembourg
}

export function Header({ path, language, selectOtherLang }) {

  const link = "https://app-postocards.firebaseapp.com" ;//'https://tim-app-postocards-test.firebaseapp.com';

  const [menu, setMenu] = useState(false);
  return (
    <div>
      <Container>
        <header>
          <nav>
            <div className="title">
              <div className="title-content">
                <img
                  draggable="false"
                  className="iconPostocards"
                  src={iconPostocards}
                  alt="iconPostocards"
                />
              </div>
            </div>
            <div className="title-page">
              <div className={`pageLink ${path === "/" ? "select" : ""}`}>
                <Link to="/">
                  <p> <FormattedMessage id="3.1" defaultMessage="CGV" /></p>
                </Link>
              </div>
              <div
                className={`pageLink ${
                  path === "application" ? "select" : ""
                }`}
              >
                <a href={link} target="_blank">
                  <p><FormattedMessage id="3.5" defaultMessage="CGV" /></p>
                </a>
              </div>
              <div
                className={`pageLink ${
                  path === "publipostage" ? "select" : ""
                }`}
              >
                <Link to="/publipostage">
                  <p><FormattedMessage id="3.2" defaultMessage="CGV" /></p>
                </Link>
              </div>
              <div
                className={`pageLink ${
                  path === "qui-sommes-nous" ? "select" : ""
                }`}
              >
                <Link to="/qui-sommes-nous">
                  <p><FormattedMessage id="3.3" defaultMessage="CGV" /></p>
                </Link>
              </div>
              <div
                className={`pageLink ${
                  path === "des-questions" ? "select" : ""
                }`}
              >
                <Link to="/des-questions">
                  <p><FormattedMessage id="3.4" defaultMessage="CGV" /></p>
                </Link>
              </div>
              
             
              <div className="select-flag">
                <img
                  draggable="false"
                  className="flag"
                  src={flagChoice[language]}
                  alt="FRANCE"
                />
                <h2>{language.toUpperCase()}</h2>
                <div className="selectLang">
                  <div
                    className={`contentSelectLang${language == "fr" ? ' selectItem' : ''}`}
                    onClick={() => selectOtherLang("fr")}
                  >
                     <img
                  draggable="false"
                  className="flag"
                  src={france}
                  alt="FRANCE"
                />
                    <p>FR</p>
                  </div>
                  <div
                    className={`contentSelectLang${language == "en" ? ' selectItem' : ''}`}
                    onClick={() => selectOtherLang("en")}
                  >
                        <img
                  draggable="false"
                  className="flag"
                  src={angleterre}
                  alt="angleterre"
                />
                    <p>EN</p>
                  </div>
                  <div
                    className={`contentSelectLang${language == "nl" ? ' selectItem' : ''}`}
                    onClick={() => selectOtherLang("nl")}
                  >
                        <img
                  draggable="false"
                  className="flag"
                  src={netherlands}
                  alt="netherlands"
                />
                    <p>NL</p>
                  </div>
                  <div
                    className={`contentSelectLang${language == "de" ? ' selectItem' : ''}`}
                    onClick={() => selectOtherLang("de")}
                  >
                        <img
                  draggable="false"
                  className="flag"
                  src={allemagne}
                  alt="allemagne"
                />
                    <p>DE</p>
                  </div>
                  <div
                    className={`contentSelectLang${language == "it" ? ' selectItem' : ''}`}
                    onClick={() => selectOtherLang("it")}
                  >
                        <img
                  draggable="false"
                  className="flag"
                  src={italie}
                  alt="italie"
                />
                    <p>IT</p>
                  </div>
                  <div
                    className={`contentSelectLang${language == "pt" ? ' selectItem' : ''}`}
                    onClick={() => selectOtherLang("pt")}
                  >
                        <img
                  draggable="false"
                  className="flag"
                  src={portugal}
                  alt="portugal"
                />
                    <p>PT</p>
                  </div>
                  <div
                    className={`contentSelectLang${language == "fr" ? ' selectItem' : ''}`}
                    onClick={() => selectOtherLang("es")}
                  >
                        <img
                  draggable="false"
                  className="flag"
                  src={espagne}
                  alt="espagne"
                />
                    <p>ES</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="burger" onClick={() => setMenu(!menu)}>
              <div style={{ backgroundColor: !menu ? 'black' : 'white' }} className="burger-line" />
              <div style={{ backgroundColor: !menu ? 'black' : 'white' }} className="burger-line" />
              <div style={{ backgroundColor: !menu ? 'black' : 'white' }} className="burger-line" />
            </div>
            <div
              className="burger-display"
              style={{
                display: menu ? "flex" : "none",
                width: "45%",
                height: window.innerHeight,
              }}
            >
              <div
                onClick={() => setMenu(false)}
                className={`pageLink${path === "/" ? " select" : ""}`}
              >
                <Link to="/">
                  <p><FormattedMessage id="3.1" defaultMessage="CGV" /></p>
                </Link>
              </div>
              <div
              onClick={() => setMenu(false)}
                className={`pageLink ${
                  path === "application" ? "select" : ""
                }`}
              >
                <a href={link} target="_blank">
                  <p><FormattedMessage id="3.5" defaultMessage="CGV" /></p>
                </a>
              </div>
              <div
                onClick={() => setMenu(false)}
                className={`pageLink${
                  path === "publipostage" ? " select" : ""
                }`}
              >
                <Link to="/publipostage">
                  <p><FormattedMessage id="3.2" defaultMessage="CGV" /></p>
                </Link>
              </div>
              <div
                onClick={() => setMenu(false)}
                className={`pageLink${
                  path === "qui-sommes-nous" ? " select" : ""
                }`}
              >
                <Link to="/qui-sommes-nous">
                  <p><FormattedMessage id="3.3" defaultMessage="CGV" /></p>
                </Link>
              </div>
              <div
                onClick={() => setMenu(false)}
                className={`pageLink${
                  path === "des-questions" ? " select" : ""
                }`}
              >
                <Link to="/des-questions">
                  <p><FormattedMessage id="3.4" defaultMessage="CGV" /></p>
                </Link>
              </div>
              
              <div className="select-flag mobile">
                <img
                  draggable="false"
                  className="flag"
                  src={flagChoice[language]}
                  alt="FRANCE"
                />
                <h2>{language.toUpperCase()}</h2>
                <div className="selectLang">
                  <div
                    className={`contentSelectLang${language == "fr" ? ' selectItem' : ''}`}
                    onClick={() => selectOtherLang("fr")}
                  >
                     <img
                  draggable="false"
                  className="flag"
                  src={france}
                  alt="FRANCE"
                />
                    <h2>FR</h2>
                  </div>
                  <div
                    className={`contentSelectLang${language == "en" ? ' selectItem' : ''}`}
                    onClick={() => selectOtherLang("en")}
                  >
                        <img
                  draggable="false"
                  className="flag"
                  src={angleterre}
                  alt="angleterre"
                />
                    <h2>EN</h2>
                  </div>
                  <div
                    className={`contentSelectLang${language == "nl" ? ' selectItem' : ''}`}
                    onClick={() => selectOtherLang("nl")}
                  >
                        <img
                  draggable="false"
                  className="flag"
                  src={netherlands}
                  alt="netherlands"
                />
                    <h2>NL</h2>
                  </div>
                  <div
                    className={`contentSelectLang${language == "de" ? ' selectItem' : ''}`}
                    onClick={() => selectOtherLang("de")}
                  >
                        <img
                  draggable="false"
                  className="flag"
                  src={allemagne}
                  alt="allemagne"
                />
                    <h2>DE</h2>
                  </div>
                  <div
                    className={`contentSelectLang${language == "it" ? ' selectItem' : ''}`}
                    onClick={() => selectOtherLang("it")}
                  >
                        <img
                  draggable="false"
                  className="flag"
                  src={italie}
                  alt="italie"
                />
                    <h2>IT</h2>
                  </div>
                  <div
                    className={`contentSelectLang${language == "pt" ? ' selectItem' : ''}`}
                    onClick={() => selectOtherLang("pt")}
                  >
                        <img
                  draggable="false"
                  className="flag"
                  src={portugal}
                  alt="portugal"
                />
                    <h2>PT</h2>
                  </div>
                  <div
                    className={`contentSelectLang${language == "fr" ? ' selectItem' : ''}`}
                    onClick={() => selectOtherLang("es")}
                  >
                        <img
                  draggable="false"
                  className="flag"
                  src={espagne}
                  alt="espagne"
                />
                    <h2>ES</h2>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  const lang = state.language;
  return {
    language: lang.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectOtherLang: (lang) => {
      dispatch(changeLang(lang));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
