import React from 'react';
import { Link } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import iconPostocards from '../../images/iconPostocards.png';
import './index.scss';

export function HeaderAccount({path}) {
  return (
    <div>
       <Container>
          <header>
            <nav className='header-account'>
                <Link to='/'>
                  <div className='title'>
                      <img draggable="false"
                          className="iconPostocards"
                          src={iconPostocards}
                          alt="iconPostocards"
                          />
                      <h2>Postocards</h2>
                  </div>
              </Link>
            </nav>
          </header>
      </Container>
     </div>
  );
}

export default HeaderAccount;
