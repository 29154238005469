import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import thunk from 'redux-thunk';
import Cookie from 'js-cookie';
import { languageReducer } from './reducers/languageReducer';

const language = Cookie.getJSON('language') || { language: 'en' };

const initialState = {
  language,
};

const reducer = combineReducers({
  language: languageReducer,
});

const store = createStore(
  reducer,
  initialState,
  compose(applyMiddleware(thunk)),
);
export default store;
