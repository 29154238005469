import React from 'react';
import './index.scss';

export function Error() {
  return (
    <div className="error">
        <h2>ERROR 404</h2>
    </div>
  );
}

export default Error;
