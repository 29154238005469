import { LANG_CHANGE } from '../constants/langConstants';

function languageReducer(state = { language: 'en' }, action) {
  switch (action.type) {
    case LANG_CHANGE:
      return { language: action.payload };
    default:
      return state;
  }
}
export { languageReducer };
