import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './store';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './fonts/Poppins-Bold.ttf';
import './fonts/Poppins-Regular.ttf';

import * as firebase from 'firebase';
import 'firebase/storage';

var firebaseConfig = {
  apiKey: 'AIzaSyCkBfIlW62O8SQseTnvWWKSH6qS7fT1OWQ',
  authDomain: 'card4u-9d3a1.firebaseapp.com',
  databaseURL: 'https://card4u-9d3a1.firebaseio.com',
  projectId: 'card4u-9d3a1',
  storageBucket: 'card4u-9d3a1.appspot.com',
  messagingSenderId: '877963124876',
  appId: '1:877963124876:web:915f60c3d0c8e5f70c1ba9',
  measurementId: 'G-PE2P5FPCEL',
};

// var firebaseConfig = {
//   apiKey: 'AIzaSyAnjcAcw5IYJuCxs4A4XZSpDmREdiBNmJk',
//   authDomain: 'postocards-test.firebaseapp.com',
//   projectId: 'postocards-test',
//   storageBucket: 'postocards-test.appspot.com',
//   messagingSenderId: '479288249769',
//   appId: '1:479288249769:web:010d31be59f7ca9c4ac8b8',
//   measurementId: 'G-S4ZQYKLKCK',
// };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <CssBaseline />
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
