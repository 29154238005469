import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import { Header, Footer } from './components';
import {
  Home,
  Error,
  Contact,
  About,
  Cgv,
  ContentSociete,
  Publipostage,
} from './screens';

import { IntlProvider } from 'react-intl';
import cgv_en from './screens/Cgv/traduction/en.json';
import cgv_fr from './screens/Cgv/traduction/fr.json';
import cgv_it from './screens/Cgv/traduction/it.json';
import cgv_nl from './screens/Cgv/traduction/nl.json';
import cgv_pt from './screens/Cgv/traduction/pt.json';
import cgv_es from './screens/Cgv/traduction/es.json';
import cgv_de from './screens/Cgv/traduction/de.json';

import home_en from './screens/Home/traduction/en.json';
import home_fr from './screens/Home/traduction/fr.json';
import home_nl from './screens/Home/traduction/nl.json';
import home_pt from './screens/Home/traduction/pt.json';
import home_it from './screens/Home/traduction/it.json';
import home_de from './screens/Home/traduction/de.json';
import home_es from './screens/Home/traduction/es.json';


import about_en from './screens/About/traduction/en.json';
import about_fr from './screens/About/traduction/fr.json';
import about_nl from './screens/About/traduction/nl.json';
import about_pt from './screens/About/traduction/pt.json';
import about_it from './screens/About/traduction/it.json';
import about_de from './screens/About/traduction/de.json';
import about_es from './screens/About/traduction/es.json';

import contact_en from './screens/Contact/traduction/en.json';
import contact_fr from './screens/Contact/traduction/fr.json';
import contact_de from './screens/Contact/traduction/de.json';
import contact_es from './screens/Contact/traduction/es.json';
import contact_it from './screens/Contact/traduction/it.json';
import contact_nl from './screens/Contact/traduction/nl.json';

import publipostage_fr from './screens/Publipostage/traduction/fr.json';
import publipostage_en from './screens/Publipostage/traduction/en.json';
import publipostage_it from './screens/Publipostage/traduction/it.json';
import publipostage_de from './screens/Publipostage/traduction/de.json';
import publipostage_nl from './screens/Publipostage/traduction/nl.json';
import publipostage_pt from './screens/Publipostage/traduction/pt.json';
import publipostage_es from './screens/Publipostage/traduction/es.json';

import all_en from './components/Footer/traduction/en.json';
import all_fr from './components/Footer/traduction/fr.json';
import all_de from './components/Footer/traduction/de.json';
import all_es from './components/Footer/traduction/es.json';
import all_it from './components/Footer/traduction/it.json';
import all_nl from './components/Footer/traduction/nl.json';
import all_pt from './components/Footer/traduction/pt.json';


import contentSociete_en from './screens/ContentSociete/traduction/en.json';
import contentSociete_fr from './screens/ContentSociete/traduction/fr.json';
import contentSociete_de from './screens/ContentSociete/traduction/de.json';
import contentSociete_es from './screens/ContentSociete/traduction/es.json';
import contentSociete_it from './screens/ContentSociete/traduction/it.json';
import contentSociete_nl from './screens/ContentSociete/traduction/nl.json';
import contentSociete_pt from './screens/ContentSociete/traduction/pt.json';

import { connect } from 'react-redux';

const messages = {
  cgv_en,
  cgv_fr,
  cgv_it,
  cgv_pt,
  cgv_de,
  cgv_nl,
  cgv_es,
  home_fr,
  home_en,
  home_nl,
  home_pt,
  home_it,
  home_de,
  home_es,
  about_fr,
  about_en,
  about_nl,
  about_pt,
  about_it,
  about_de,
  about_es,
  contact_fr,
  contact_en,
  contact_de,
  contact_es,
  contact_it,
  contact_nl,
  publipostage_fr,
  publipostage_en,
  publipostage_it,
  publipostage_de,
  publipostage_nl,
  publipostage_pt,
  publipostage_es,
  all_fr,
  all_en,
  all_de,
  all_es,
  all_it,
  all_nl,
  all_pt,
  contentSociete_fr,
  contentSociete_en,
  contentSociete_de,
  contentSociete_es,
  contentSociete_it,
  contentSociete_nl,
  contentSociete_pt,
};

function DefaultLayout(props) {
  return (
    <>
      <IntlProvider
                locale="fr"
                messages={messages[`all_${props.language}`]}
              >
      <Header path={props.path} />
    </IntlProvider>
      <Container style={{ marginTop: '5rem' }}>
        {props.children}
      </Container>
      <IntlProvider
        locale="fr"
        messages={messages[`all_${props.language}`]}
      >
        <Footer />
      </IntlProvider>
    </>
  );
}

function Layout(props) {
  return (
    <>
      <IntlProvider
                locale="fr"
                messages={messages[`all_${props.language}`]}
              >
      <Header path={props.path} />
     </IntlProvider>
      {props.children}
      <IntlProvider
        locale="fr"
        messages={messages[`all_${props.language}`]}
      >
        <Footer />
      </IntlProvider>
    </>
  );
}

const AppRouting = (props) => {
  return (
    <IntlProvider locale={`${props.language}`}>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Layout path="/" language={props.language}>
              <IntlProvider
                locale="fr"
                messages={messages[`home_${props.language}`]}
              >
                <Home />
              </IntlProvider>
            </Layout>
          )}
        />
        <Route
          exact
          path="/des-questions"
          render={() => (
            <Layout path="des-questions" language={props.language}>
              <IntlProvider
                locale="fr"
                messages={messages[`contact_${props.language}`]}
              >
                <Contact />
              </IntlProvider>
            </Layout>
          )}
        />
        <Route
          exact
          path="/qui-sommes-nous"
          render={() => (
            <DefaultLayout
              path="qui-sommes-nous"
              language={props.language}
            >
                <IntlProvider
                locale="fr"
                messages={messages[`about_${props.language}`]}
              >
              <About />
              </IntlProvider>
            </DefaultLayout>
          )}
        />
        <Route
          exact
          path="/mention-legal"
          render={() => (
            <DefaultLayout
              path="mention-legal"
              language={props.language}
            >
              <IntlProvider
                locale="fr"
                messages={messages[`cgv_${props.language}`]}
              >
                <Cgv />
              </IntlProvider>
            </DefaultLayout>
          )}
        />
        <Route
          exact
          path="/cgv"
          render={() => (
            <DefaultLayout path="cgv" language={props.language}>
              <IntlProvider
                locale="fr"
                messages={messages[`cgv_${props.language}`]}
              >
                <Cgv />
              </IntlProvider>
            </DefaultLayout>
          )}
        />
        <Route
          path="/publipostage"
          exact
          render={() => (
            <DefaultLayout
              path="publipostage"
              language={props.language}
            >
              <IntlProvider
                locale="fr"
                messages={messages[`publipostage_${props.language}`]}
              >
                <Publipostage />
              </IntlProvider>
            </DefaultLayout>
          )}
        />

        <Route
          path="/api"
          render={({history}) => (
            <DefaultLayout
              path="contentSociete"
              language={props.language}
            >
              <IntlProvider
                locale="fr"
                messages={messages[`contentSociete_${props.language}`]}
              >
                <ContentSociete history={history} />
              </IntlProvider>
            </DefaultLayout>
          )}
        />
        <Route
          render={() => (
            <DefaultLayout language={props.language}>
              <Error />
            </DefaultLayout>
          )}
        />
      </Switch>
    </IntlProvider>
  );
};

const mapStateToProps = (state) => {
  const lang = state.language;
  return {
    language: lang.language,
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     submitLogin: (login, password) => {
//       // dispatch(postLogin(login, password));
//     },
//   };
// };

export default connect(mapStateToProps, null)(AppRouting);
