import React, { PureComponent } from 'react'
import { default as SlickSlider } from 'react-slick'
import './Slider.scss'

const sortedSlides = [
  'https://firebasestorage.googleapis.com/v0/b/card4u-9d3a1.appspot.com/o/public%2Fphoto_11.jpg?alt=media&token=788e79bb-145b-42f9-bec3-d73cb6059f73',
  'https://firebasestorage.googleapis.com/v0/b/card4u-9d3a1.appspot.com/o/public%2Fphoto_17.jpg?alt=media&token=561f28c6-974e-4d8e-91f4-deec0f99dd31',
  'https://firebasestorage.googleapis.com/v0/b/card4u-9d3a1.appspot.com/o/public%2Fphoto_40.jpg?alt=media&token=86646fa4-ef51-46e9-b7cb-f149e0c0183b',
  'https://firebasestorage.googleapis.com/v0/b/card4u-9d3a1.appspot.com/o/public%2Fphoto_43.jpg?alt=media&token=cb2d8c24-4fb0-47a0-960c-bcd33cfd58d2',
  'https://firebasestorage.googleapis.com/v0/b/card4u-9d3a1.appspot.com/o/public%2Fphoto_50.jpg?alt=media&token=78005f35-3906-4226-884a-39f44b17a10f'
]

export default class Slider extends PureComponent {
  state = {
    sliderMainSettings: {
      navigation: {
        draggable: false,
        pauseOnHover: false,
        swipe: false,
        touchMove: false
      },
      templating: {
        arrows: false,
        dots: false,
        fade: true,
        useCss: false,
        useTransform: false,
        adaptativeHeight: true
      },
      play: {
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000
      }
    },
    currentSlide: 0,
    currentSlideDuration: 0
  }

  handleSlideInit = () => {
    this.setState({
      currentSlideDuration: 5 * 1000
    })
  }

  handleBeforeSlideChange = () => {
    this.setState(prevState => ({
      ...prevState,
      currentSlide: prevState.currentSlide === sortedSlides.length - 1 ? 0 : prevState.currentSlide + 1,
      currentSlideDuration: 5 * 1000
    }))
  }

  setVideoDuration = (duration) => {
    this.setState({
      currentSlideDuration: duration * 1000
    })
  }

  render () {
    return (
      <SlickSlider
        {...this.state.sliderMainSettings.navigation}
        {...this.state.sliderMainSettings.templating}
        {...this.state.sliderMainSettings.play}
        autoplaySpeed={this.state.currentSlideDuration}
        beforeChange={this.handleBeforeSlideChange}
        onInit={this.handleSlideInit}
      >
        {sortedSlides.map((image, alt) => <div key={alt} className='ad-li'>
          <img
            alt={`image-${alt}`}
            className='ad-li-media'
            src={image}
          />
        </div>
        )}
      </SlickSlider>
    )
  }
}
