import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';

import Snackbar from '@material-ui/core/Snackbar';
import publipostage from '../../images/publipostage.png';
import logoPubli from '../../images/logoPubli.svg';

import * as firebase from 'firebase';
import './index.scss';

import MuiAlert from '@material-ui/lab/Alert';

import { FormattedMessage } from 'react-intl';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'linear-gradient(90deg, #0998ce 30%, #6fbbd5 86%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgb(150 148 148 / 30%);',
    color: 'white',
    height: 48,
    padding: '0 30px',
    fontWeight: 'bold',
    marginLeft: 20,
    marginBottom: 10
  },
  upload: {
    background: 'linear-gradient(90deg, #DE3D00 30%, #f57c00 86%)',
    zIndex: 1,
  },
  add: {
    background: 'linear-gradient(90deg, #D0D0D0 30%, #D0D0D0 86%)',
    color: 'black',
    borderRadius: 18,
  },
}));

const ContentSociete = (props) => {
  //Au click du a href on le fais telecharger sont fichier exel avec la 1premiere ligne remplis

  const classes = useStyles();

  const [state, setState] = useState({
    frontStamp: '',
    id: '',
    front: '',
    signature: '',
    short_video: '',
    productId: 'postcard',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [send, setSend] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false)
    setSend(false);
  };


  const [text, setText] = useState('');

  const storage = firebase.storage();
  const db = firebase.firestore();

  useEffect(() => {
    let params = new URLSearchParams(
      document.location.search.substring(1),
    );
    let token = params.get('token');

    if (token) {
      try {
        const societyInWaiting = db
          .collection('societyInWaiting')
          .doc(token);
        societyInWaiting.get().then((doc) => {
          if (!doc.exists) {
            console.log('No such document!');
            props.history.push('/publipostage');
          } else {
            const data = doc.data();

            // if (data.url) {
            //   //POP UP ERROR
            // } else {
            // @ts-ignore
            setState(data);
            // }
          }
        });
      } catch (err) {
        console.log('ERROR', err);
        props.history.push('/publipostage');
      }
    } else {
      props.history.push('/publipostage');
    }
  }, []);

  const [file, setFile] = useState(null);

  const [message, setMessage] = useState('Server error');

  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async (event) => {
    event.preventDefault();

    if (!file) {
      setMessage('Pas de fichier ajouté')
      return setError(true)
    }

    if (!loading) {
      setLoading(true);
    }

    const dateObj = new Date();
    const month = +dateObj.getUTCMonth() + 1;
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();

    let datePath = '/society/' + year + '/' + month + '/' + day + '/';

    const firebaseXLSXFilePath = datePath + file.name;

    const uploadTask = storage.ref(firebaseXLSXFilePath).put(file);

    uploadTask.on(
      'state_changed',
      () => {},
      (error) => console.error(error),
      () => {
        storage
          .ref(datePath)
          .child(file.name)
          .getDownloadURL()
          .then(async (url) => {
            const doc = db
            .collection('societyInWaiting')
            .doc(state.id);

            await doc.update({ url });

            doc.get().then((res) => {
              if (!res.exists) {
                console.log('No such document! AFTER LAUNCH DOC');
              } else {
                const data = res.data();

            if (data?.paid) {
              const callableReturnMessage = firebase
              .functions()
              .httpsCallable('createPrintSociety');

              callableReturnMessage({
                id: state.id
              })
              .then((result) => {
                console.log('RES', result)
                setMessage('File upload with success')
                setSend(true);
                setFile(null);
                setLoading(false)
              })
              .catch((error) => {
                console.log(`error: ${JSON.stringify(error)}`);
                setLoading(false);
                setError(true);
              });
            } else {
              setMessage('File upload with success')
              setSend(true);
              setFile(null);
              setLoading(false)
            }
              }
            });
          })
          .catch((err) => {
            setFile(null);
            setError(true)
            setLoading(false);
          });
      },
    );
  };

  const handleUploadXlsx = () => {
    console.log('XSLXS REQUETE');
  };
  const handleChangeInput = (e) => {
    console.log(e.target.value);
    setText(e.target.value);
  };

  return (
    <>
    <section className="society">

      <div className="content-society">
        <h2><FormattedMessage id="1" defaultMessage="publipostage" /></h2>
        <h3>1. <FormattedMessage id="1.0" defaultMessage="publipostage" /></h3>
        {state.front && state.front !== '' && <img className="front3" src={state.front}></img>}
        {state.productId.includes('postcard') ? (
          <>
            <div className="verssoPosto">
              <div className="content">
                <div className="contentText">
                  <textarea
                    onChange={handleChangeInput}
                    value={text}
                  ></textarea>
                  <div className='container-bottom-card'>
                  {state.signature && state.signature !== '' &&
                    !state.productId.includes('postcard-mini') && (
                      <img
                      className="signature"
                      src={state.signature}
                      ></img>
                      )}
                  {state.short_video && state.short_video !== '' &&
                    !state.productId.includes('postcard-mini') && (
                      <div style={{marginLeft: state.signature ? '0%' : '78%'}} className='qr-video-container'>
                        <p className="video-qr">Vidéo</p>
                        <img
                          className="qrcode"
                          src="https://firebasestorage.googleapis.com/v0/b/postocards-test.appspot.com/o/public%2FQRCODE.png?alt=media&token=ff71f886-70bb-427b-9276-b8afb2feb2f1"
                          ></img>
                      </div>
                    )}
                  </div>
                </div>
                <div className="separateur"></div>
                <div className="contentAdresse">
                  {state.frontStamp && state.frontStamp !== '' && (
                    <img
                      className="timbre"
                      src={state.frontStamp}
                    ></img>
                  )}
                  <div className="dest">
                    <p className="dest-title"><FormattedMessage id="1.2" defaultMessage="publipostage" />:</p>
                    <p className="address">M. Postocards</p>
                    <p className="address">
                      5, postostreet
                    </p>
                    <p className="address">1000 Springfield</p>
                    <p className="address">France</p>
                  </div>
                  <div className="bottomPage">
                    <img className="logo" src={logoPubli}></img>
                    <p className="strong logoName">Postocards.com</p>
                  </div>

                  <img
                    className="qrcode-app"
                    src="https://firebasestorage.googleapis.com/v0/b/postocards-test.appspot.com/o/public%2FQRCODE.png?alt=media&token=ff71f886-70bb-427b-9276-b8afb2feb2f1"
                  ></img>
                </div>
              </div>
            </div>
          </>
        ) : (
          <p>Pas de texte à donner</p>
        )}
        {state.front && state.front !== '' && <img className="front" src={state.front}></img>}
        {state.front && state.front !== '' && <img className="front2" src={state.front}></img>}
        <h3><FormattedMessage id="2" defaultMessage="publipostage" /></h3>
        <a href="https://firebasestorage.googleapis.com/v0/b/card4u-9d3a1.appspot.com/o/society%2FPublipostage.xlsx?alt=media&token=f323ff36-67e6-4fe1-889a-d93196553931">
          <Button
            onClick={() => handleUploadXlsx()}
            // @ts-ignore
            className={[classes.root, classes.upload]}
            id="2.1"
          >
          2.<FormattedMessage id="2.1" defaultMessage="publipostage" />
          </Button>
        </a>
        <h3>3. <FormattedMessage id="3" defaultMessage="publipostage" /></h3>
        <h3>4. <FormattedMessage id="4" defaultMessage="publipostage" /></h3>
        <div>
          <form onSubmit={handleUpload}>
            {file && <p>{file.name}</p>}
            <Button
              // disabled={file}
              variant="contained"
              component="label"
              // @ts-ignore
              className={[classes.root, classes.add]}
              startIcon={<CloudUploadIcon />}
            >
              <FormattedMessage id="5" defaultMessage="publipostage" />
              <input
                type="file"
                hidden
                onChange={handleChange}
                accept=".xlsx, .xls"
              />
            </Button>
            {loading ? <div style={{marginLeft: 20, marginTop: 10}}>
              <CircularProgress />
              </div> : <Button type="submit" className={classes.root}>
              <FormattedMessage id="6" defaultMessage="publipostage" />
            </Button>}
          </form>
        </div>
      </div>
      <img
        draggable="false"
        className="image-page"
        src={publipostage}
        alt="publipostage"
      ></img>
    </section>
      <Snackbar open={send} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
        {message}
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
        {message}
        </Alert>
      </Snackbar>
</>
  );
};

export default ContentSociete;
