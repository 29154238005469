import React from 'react';
import Grid from '@material-ui/core/Grid';

import publipostage from '../../images/publi.png';
import lots from '../../images/lots.png';

import letterOrangeAbout from '../../images/letterOrangeAbout.svg';
import floconBlack from '../../images/floconBlack.svg';
import floconBlue from '../../images/floconBlue.svg';
import traitBlue from '../../images/home/traitBlue.svg';

import './index.scss';
import { FormattedMessage } from 'react-intl';

export function Publipostage() {
  return (
    <section className="publipostage-container">
      <div className="content-publi">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className="content-image">
              <img
                draggable="false"
                className="image-made"
                src={publipostage}
                alt="postcards and smiley"
              />
              <img
                draggable="false"
                className="letter-orange"
                src={letterOrangeAbout}
                alt="letter-orange"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className="container">
            <div className="content-title">
              <h2>
                <FormattedMessage id="1" defaultMessage="CGV" />
              </h2>
              <img
                draggable="false"
                className="image-title"
                src={traitBlue}
                alt="decoration-blue"
              />
            </div>
            <div className="line-color">
              <div className="color-1" />
              <div className="color-2" />
              <div className="color-1" />
              <div className="color-2" />
              <div className="color-1" />
              <div className="color-2" />
            </div>
            <div className="content-text">
              <p>
                <FormattedMessage id="1.1" defaultMessage="CGV" />
              </p>
              <p>
                <FormattedMessage id="1.2" defaultMessage="CGV" />
              </p>
              <p>
                <FormattedMessage id="1.3" defaultMessage="CGV" />
              </p>
              <p>
                <FormattedMessage id="1.4" defaultMessage="CGV" />
              </p>
              <p>
                <FormattedMessage id="1.5" defaultMessage="CGV" />
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className="content-background">
            <div className="row-1">
              <img
                draggable="false"
                className="flocon2"
                src={floconBlue}
                alt="flocon-bleu"
              />
              <h2>
                <FormattedMessage id="2" defaultMessage="CGV" />
              </h2>
              <div className="line-color">
                <div className="color-1" />
                <div className="color-2" />
                <div className="color-1" />
                <div className="color-2" />
                <div className="color-1" />
                <div className="color-2" />
              </div>
              <div className="content-text">
                <p>
                  <FormattedMessage id="2.1" defaultMessage="CGV" />
                </p>
                <p>
                  <FormattedMessage id="2.2" defaultMessage="CGV" />
                </p>
                <p>
                  <FormattedMessage id="2.3" defaultMessage="CGV" />
                </p>
                <p>
                  <FormattedMessage id="3" defaultMessage="CGV" />
                </p>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} className="content-background2">
            <img
              draggable="false"
              className="flocon1"
              src={floconBlack}
              alt="flocon-black"
            />
            <div className="content-image lot-image">
              <img
                draggable="false"
                className="image-made shadow"
                src={lots}
                alt="postcards and smiley"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
}

export default Publipostage;
